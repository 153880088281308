module.exports = {
  siteTitle: 'Ray2019', // <title>
  manifestName: 'directive',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'RESCUE ACTON BY YOUTH',
  subHeading: '青年學生體檢政府網站計畫',
  projects: [
    {
      id: 1,
      name: "個人健保資料網路服務作業",
      issuu: "//e.issuu.com/embed.html?d=____________________b68532d3ebc087&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://eservice.nhi.gov.tw/Personal1/System/mLogin.aspx",
      prototype: "https://projects.invisionapp.com/share/QUT2VDRE849?fbclid=IwAR1b8EN6d7ZWuaezvFbEtFetY6rzW4eoOI-U2-AcbedPqdeJU2pZyQIUf38#/screens/374936313",
    },
    {
      id: 2,
      name: "勞保個人網路申報及查詢作業",
      issuu: "//e.issuu.com/embed.html?d=_________________dcbb8282ecbd39&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://edesk.bli.gov.tw/na/",
      prototype: "https://xd.adobe.com/view/7d3297b0-a02e-48b3-5681-8d2c317d42b5-0b03/?fullscreen&hints=off",
    },
    {
      id: 3,
      name: "NCC意見信箱（通訊傳播業務陳情網）",
      issuu: "//e.issuu.com/embed.html?d=ncc_________.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://cabletvweb.ncc.gov.tw/SWSFront35/SWSF/SWSF01014.aspx",
      prototype: "/",
    },
    {
      id: 4,
      name: "台鐵線上一般訂票系統",
      issuu: "//e.issuu.com/embed.html?d=ray3.0____-__.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://tip.railway.gov.tw/tra-tip-web/tip/tip001/tip121/query",
      prototype: "/",
    },
    {
      id: 5,
      name: "國立故宮博物院網路線上報名系統",
      issuu: "//e.issuu.com/embed.html?d=___________________7_30_.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://signup.npm.edu.tw/",
      prototype: "https://invis.io/QJT21UP27R5",
    },
    {
      id: 6,
      name: "科技部全球資訊網",
      issuu: "//e.issuu.com/embed.html?d=__________________8_30_.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://www.most.gov.tw/",
      prototype: "https://xd.adobe.com/view/dfaaa92e-1732-45ab-5d7a-c5a622b36b9f-a99b/?fullscreen",
    },
    {
      id: 7,
      name: "臺灣登山整合資訊網",
      issuu: "//e.issuu.com/embed.html?d=_____final_presentation&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://mountain.cpami.gov.tw/Index.aspx",
      prototype: "https://xd.adobe.com/view/d99a87d9-9e9d-45bb-6bc7-e72798977083-afab/?fullscreen",
    },
    {
      id: 8,
      name: "郵務業務專區入口網",
      issuu: "//e.issuu.com/embed.html?d=_____final_presentation.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://www.post.gov.tw/post/internet/Postal/default.jsp",
      prototype: "https://xd.adobe.com/view/de597c8d-3fc1-4632-4c4a-ac040f0e9749-a868/?fullscreen",
    },
    {
      id: 9,
      name: "新專利電子申請系統",
      issuu: "//e.issuu.com/embed.html?d=_____ppt.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://tiponet.tipo.gov.tw/S040/Download045File",
      prototype: "https://xd.adobe.com/view/113479f5-7b46-42bf-56f9-b4a358b3e5e9-6705/",
    },
    {
      id: 10,
      name: "網路繳稅服務",
      issuu: "//e.issuu.com/embed.html?d=_____ppt__1_.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://paytax.nat.gov.tw/",
      prototype: "https://z1640q.axshare.com/#id=mcfwz7&p=首頁",
    },
    {
      id: 11,
      name: "三軍總醫院網路預約掛號系統",
      issuu: "//e.issuu.com/embed.html?d=20190730_v2.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://www2.ndmctsgh.edu.tw/webreg/default.aspx",
      prototype: "https://xd.adobe.com/view/2fbe2581-244c-4c24-70ce-c0d811476537-598f/?fbclid=IwAR0oyWFhrye3XxNLM7LIcSRc-fQosj68ej4ed93ypcwWHUJYleACy_cwdMU",
    },
    {
      id: 12,
      name: "內政部警政署交通事故資料申請系統",
      issuu: "//e.issuu.com/embed.html?d=20190830______________final_version.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://tm2.npa.gov.tw/",
      prototype: "https://share.protopie.io/SAgJfb5h6Sa",
    },
    {
      id: 13,
      name: "龜山島登島線上申請",
      issuu: "//e.issuu.com/embed.html?d=ray_3.0______________.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://www.necoast-nsa.gov.tw/Coast/",
      prototype: "https://xd.adobe.com/spec/ec67b728-7b99-44dd-5e5f-6b5402cde49a-9a56/screen/cd9b5cee-ef88-4533-992e-ea336fc2e4fb/Web-1920-18/",
    },
    {
      id: 14,
      name: "全國動物收容管理系統",
      issuu: "//e.issuu.com/embed.html?d=____-ray_3.0_____-____.pptx&pageLayout=singlePage&hideIssuuLogo=true&u=pdis.tw",
      current: "https://asms.coa.gov.tw/",
      prototype: "https://xd.adobe.com/view/7f5d8219-9022-4760-53c5-26253f08381b-096c/",
    },
  ],
};
